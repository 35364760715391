const binding = { modules: {}, dataActions: {} };

    (binding.modules['footer-category'] = {
        c: () => require('@msdyn365-commerce-modules/footer/dist/lib/modules/footer-category/footer-category'),
        $type: 'containerModule',
        da: [],
        
        iNM: true,
        ns: '@msdyn365-commerce-modules',
        n: 'footer-category',
        p: 'footer',
        
        pdp: '',
        
        
        md: 'node_modules/@msdyn365-commerce-modules/footer/dist/lib/modules/footer-category'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };
                        export const viewDictionary = {};
                        viewDictionary['__local__|__local__|themes|mattressfirm|views|footer-category'] = {
                c: () => require('partner/themes/mattressfirm/views/footer-category.view.tsx'),
                cn: '__local__-__local__-footer-category'
            };
viewDictionary['@msdyn365-commerce-modules|footer|modules|footer-category|footer-category'] = {
                c: () => require('@msdyn365-commerce-modules/footer/dist/lib/modules/footer-category/footer-category.view.js'),
                cn: '@msdyn365-commerce-modules-footer-footer-category'
            };
window.__bindings__ = window.__bindings__ || {};
window.__bindings__.viewDictionary = {
                        ...window.__bindings__.viewDictionary || {},
                        ...viewDictionary
                    };